<template>
  <div class="explanation-page">
    <div class="header">
      <router-link class="index-logo" to="/">
        <img class="logo" src="@/assets/images/logo.png" />
      </router-link>
    </div>
    <div class="content-box">
      <div class="content">
        <h1>Privacy Policy</h1>
        <p>This privacy policy covers how we treat personal information that we collect and receive. We do not intend
          to
          collect any personal information from children under 13 unless we believe such collection to be permitted by
          law.
          Please see below for our policy with respect to children under 13.</p>
        <br><br><br>
        <b>General</b><br>
        <p>We gather user information to provide you with a customized experience on our site. Your user information
          helps us
          tailor the content, services, goods and advertising to your current and future needs. For this reason, we
          may ask
          you to register when you visit certain areas of our site. If you choose not to register you may not have
          access to
          areas that require personal identification.</p>
        <br><br><br>
        <b>Revisions to this Privacy Policy</b><br>
        <p>We may change this privacy policy from time to time to accommodate new technologies, industry practices,
          regulatory
          requirements or for other purposes. If we update this policy, changes will be reflected on this page and we
          will
          update the "last updated" date above. We will obtain prior parental consent for any changes we make if those
          changes
          affect Children Users in a way that requires parental consent under COPPA.</p>
        <br><br><br>
        <b>Use of Information</b><br>
        <p>When you register, we only ask for a unique nickname and password selected by you, and your password is
          encrypted
          in
          our database.</p>
        <p>All communication on the Internet takes place between pairs of IP Addresses. Our Web servers will log the
          IP
          address that any connection is made from. We may use these IP addresses to help diagnose technical problems
          or
          prevent
          abuse of our systems or other site users. Your IP address may also be used to gather aggregate demographic
          data
          and
          target advertisements accordingly.</p>
        <p>Your browser has options to accept, reject, or provide you with notice when a cookie is sent. A "cookie" is
          a
          small
          line of text that is stored with your Web browser for record-keeping purposes and to help us provide better
          service
          to you. We use cookies to save your password (in an encrypted format) on your machine so you don't have to
          re-enter
          it each time you visit our site. We also use cookies to deliver content specific to your interests.</p>
        <p>We use companies to serve the advertisements on some of our pages. These companies and advertisers may
          place and
          access their cookies on your computer. Other companies' use of their cookies is subject to their own privacy
          policies, not ours. We may use your information or allow advertisers or companies that serve advertisements
          access
          to your information to determine which advertisements you will see. This information may include your
          demographic
          information, geographic location, what content you access on our site, how you rate various content and
          other
          information.</p>
        <p>Our site contains links, including banner and other ads, to other Websites. We are not responsible for the
          privacy
          practices or the content of such websites. Our site may also link to Websites that feature our trademarks
          and
          names
          along with trademarks and names of other companies. You should look at the privacy policy on that co-branded
          Website, as the co-branded Website may not be under our control.</p>
        <p>For reasons such as improving member experience and providing customized communications to our users, we
          may
          receive
          information about you from third party sources and our web logs and add it to the information that we have
          received
          from you. Web logs automatically record anything a web server sees, which may include e-mail addresses you
          enter
          into a form or pages viewed by a user at a particular IP address.</p>
        <br><br><br>
        <b>Information Sharing</b><br>
        <p>We will not share your personally identifiable information with third parties, aside from entities that
          perform
          services for us.</p>
        <p>However, we may disclose information you provide if required to do so by law or if we have a good faith
          belief
          that
          disclosure is necessary to </p>
        <p>(1) comply with the law or with legal process served on us;</p>
        <p>(2) protect and defend our rights or property; or</p>
        <p>(3) act in an emergency to protect someone's safety.</p>
        <p>In the event that we acquired by another company, your personal information may be part of the assets
          transferred
          to
          the acquiring party.</p>
        <br><br><br>
        <b>Google Analytics</b><br>
        <p>VoVoGamer.com make use of Google Analytics Demographics and Interest Reporting. VoVoGamer.com may also make
          use of
          other Google Analytics for Display Advertising features, including Remarketing, Google Display Network
          Impression
          Reporting and/or the DoubleClick Campaign Manager integration. We are required by Google to disclose the
          following
          information to you in our Privacy Statement in connection with our use of any Google Analytics for Display
          Advertising features on VoVoGamer.com:</p>
        <p>You may opt out of Google Analytics for Display Advertising or customize Google Display Network ads by
          using the
          <a href="https://www.google.com/settings/ads" target="_blank">Google Ads Settings page</a>. You may also
          prevent
          your data from being collected and used by Google Analytics by opting out through the use of the <a
            href="https://tools.google.com/dlpage/gaoptout/" target="_blank">Google Analytics Opt-out Browser
            Add-on</a>.
        </p>
        <p>If VoVoGamer.com make use of the Google Analytics for Display Advertising Remarketing feature, VoVoGamer.com
          will
          use
          such Remarketing feature to advertise online and third party vendors, including Google, may show
          VoVoGamer.com
          ads on
          sites across the Internet.</p>
        <p>VoVoGamer and third party vendors, including Google, may use first-party cookies (such as the Google
          Analytics
          cookies) and third party cookies (such as the DoubleClick cookie) together to inform, optimize and serve ads
          based
          on a user's past visits to VoVoGamer.com, and/or (b) report how your ad impressions, other uses of ad
          services,
          and
          interactions with these ad impressions and ad services are related to visits to VoVoGamer.com.</p>
        <p>Data from Google Analytics Demographics and Interest Reporting may be utilized by VoVoGamer.com to help
          recognize
          and
          understand user preferences, to make improvements to VoVoGamer.com, to choose content and advertising to
          display
          to
          you, and for other business purposes that will allow VoVoGamer.com to better serve you.</p><br>
        <br><br><br>
        <b>Cookie Policy</b><br>
        <p>We use cookies to offer you a better browsing experience, analyze site traffic, personalize content, and
          serve
          targeted advertisements. If you continue to use this site, you consent to our use of cookies.</p>
        <p>We and our advertisers may use cookies, or similar technologies to enhance and personalize your experience
          on the
          site, including the following:</p>
        <p>* to operate and improve offerings on the site;</p>
        <p>* to help authenticate you when you are on the site;</p>
        <p>* to remember your preferences;</p>
        <p>* to present and help measure and research the effectiveness of we offerings;</p>
        <p>* to customize the content provided to you through the site; and,</p>
        <p>* to show targeted ads.</p>
        <p>We also uses 3rd party vendor re-marketing tracking cookies, including the Google Adwords tracking cookie.
          This
          means we will continue to show ads to you across the internet, specifically on the Google Content Network
          (GCN).
          As
          always we respect your privacy and are not collecting any identifiable information through the use of
          Google's
          or
          any other 3rd party remarketing system.</p>
        <p>The third-party vendors, including Google, whose services we use - will place cookies on web browsers in
          order to
          serve ads based on past visits to our website. - Third party vendors, including Google, use cookies to serve
          ads
          based on a user's prior visits to your website. This allows us to make special offers and continue to market
          our
          services to those who have shown interest in our service. You can opt out of Google Marketing Platform's use
          of
          cookies by visiting the Google Marketing Platform opt-out page or the Network Advertising Initiative opt-out
          page.</p>
        <p>Users can refuse the installation of cookies or delete them by adjusting the browser settings on his
          computer. To
          learn more on how to adjust the settings of your internet browser, we recommend the user to visit the
          website of
          the
          browser's vendor.</p>
        <p>The user can also decide to remove installed cookies. Users can do so in the following manners:</p>
        <br>
        <p>Internet Explorer</p>
        <p>Delete existing Tracking Technologies through the "Internet Options" sub-option of the "Tools" menu option
          of
          your
          browser or otherwise as directed by your browser's support feature. Disable future Tracking Technologies by
          using
          the same browser controls. Additional information on disabling most Tracking Technologies may be available
          through
          your browser's support feature. (See the "help" section of your browser for more information.)</p>
        <br>
        <p>Google Chrome</p>
        <p>Delete existing Tracking Technologies through the "Clear Browsing Data" sub-option of the "Tools" menu
          option of
          your browser or otherwise as directed by your browser's support feature. Disable future Tracking
          Technologies by
          using the same browser controls. Additional information on disabling most Tracking Technologies may be
          available
          through your browser's support feature. (See the "help" section of your browser for more information.)</p>
        <br>
        <p>Mozilla Firefox</p>
        <p>Delete existing Tracking Technologies through the "Clear Recent History" sub-option of the "History" menu
          option
          of
          your browser or otherwise as directed by your browser's support feature. Disable future Tracking
          Technologies by
          using the same browser controls. Additional information on disabling most Tracking Technologies may be
          available
          through your browser's support feature. (See the "help" section of your browser for more information.)</p>
        <p>You may be able to have the device you use to access the site warn you each time a cookie or most other
          Tracking
          Technologies is being set (other than Flash cookies), or you can choose to turn off such warnings. This is
          done
          through your browser on the device you use to access the Website. Additional information on warnings and
          removal
          of
          warnings may be available through your browser's support feature.</p>
        <p>Please note that deleting, rejecting, disabling or turning off Tracking Technologies through the above
          options
          will
          not remove Flash cookies. For more information about Flash cookies and how to remove them from your
          computer,
          please
          visit Adobe's website.</p>
        <p>Please be aware that certain areas and features of the site can only be accessed in conjunction with
          Tracking
          Technologies, and that disabling Tracking Technologies might prevent you from accessing such content.
          If you have any questions concerning cookies, please contact us.</p>
        <br><br><br>
        <b>Children Users</b><br>
        <p>We recognize the importance of safeguarding the privacy of children and encourage parents to check and
          monitor
          their
          children's use of online activities regularly. When presented with this age screen, it is important that you
          state
          the correct age. Based on your age information and your device settings, VoVoGamer does not process personal
          data of
          children under the minimum age limit (Children Users) that is prescribed by the legislation in the
          individual
          jurisdictions for behavioral advertisement.</p>
        <p>The Children Users' experience of games with an age-gate on the VoVoGamer supports and complies with the
          Children's
          Online Privacy Protection Act ("COPPA"). There is no registration required for the VoVoGamer.</p>
        <p>We believe that parents should supervise their children's online activities and consider using parental
          control
          tools available from online services and software manufacturers that help provide a kid-friendly online
          environment.
          These tools can also prevent children from otherwise disclosing online their name, address and other
          personal
          information without parental permission.</p>
        <p>VoVoGamer may collect and use IP addresses and other persistent identifiers, including cookies, from
          Children
          Users,
          but only for purposes of supporting our internal operations, such as to help us:</p>
        <p>* Identify whether you are a child or adult for data protection purposes to determine the legal
          requirements for
          our
          processing of your data</p>
        <p>* Maintain or analyze the functioning of the VoVoGamer</p>
        <p>* Perform network communications</p>
        <p>* Authenticate users of, or personalize the content on, the VoVoGamer</p>
        <p>VoVoGamer was created for our youngest audience and is fully COPPA compliant. To learn more about COPPA,
          please
          reference this simple one-page informational guide from the kidSAFE Seal Program:
        </p>
        <p>https://www.kidsafeseal.com/knowaboutcoppa.html.</p>
        <br><br><br>
        <b>DoubleClick DART and Third-Party Vendors Cookies</b><br>
        <p>We also may use DART and third-party vendors cookies for ad serving through Google's DoubleClick, which
          places a
          cookie on your computer when you are browsing the web and visit a site using DoubleClick advertising
          (including
          some
          Google AdSense advertisements). This cookie is used to serve ads specific to you and your interests
          ("interest
          based
          targeting"). The ads served will be targeted based on your previous browsing history (For example, if you
          have
          been
          viewing sites about visiting Las Vegas, you may see Las Vegas hotel advertisements when viewing a
          non-related
          site,
          such as on a site about hockey). DART cookies use "non personally identifiable information". It does NOT
          track
          personal information about you, such as your name, email address, physical address, telephone number, social
          security numbers, bank account numbers or credit card numbers. You can opt-out of this ad serving on all
          sites
          using
          this advertising by visiting <a href="http://www.networkadvertising.org/managing/opt_out.asp"
            target="_blank">http://www.networkadvertising.org/managing/opt_out.asp</a>
        </p>
        <p>Third parties, like Google, may collect certain types of information about visits to the site, including
          interaction
          with content on these sites. As always, no personally identifiable information will be collected.</p>
        <br><br><br>
        <b>Children Under 13</b><br>
        <p>Our site is not directed to children under the age of 13. We respect the privacy of parents and children
          and are
          committed to complying with the Children's Online Privacy Protection Act (COPPA). On those areas of the
          sites
          that
          ask for age, we do not collect personal information from individuals who identify themselves as under the
          age of
          13
          except in those limited circumstances where we believe that such collection is permitted under COPPA.</p>
        <p>If a parent believes that his or her child has submitted personal information to ue, he or she can contact
          us via
          e-mail. We will promptly delete the information upon learning that it relates to a child under the age of
          13.
          Please
          note that it is possible some of this information may remain archived in web logs and back-up archives after
          we
          delete the information from its active database (see above regarding web logs, and below regarding archived
          information).</p>
        <p>We believe that parents should supervise their children's online activities and consider using parental
          control
          tools available from online services and software manufacturers that help provide a kid-friendly online
          environment.
          These tools can also prevent children from otherwise disclosing online their name, address and other
          personal
          information without parental permission.</p>
        <br><br><br>
        <b>Lawful Bases for Processing Personal Data</b><br>
        <p>If you are an individual in the European Union (EU), we collect and process data about you only where we
          have
          legal
          bases for doing so under applicable EU laws. This means we collect and process your data only when:</p>
        <p>* It is necessary for a legitimate interest (which is not overridden by your individual privacy interests),
          such
          as
          preventing fraud, improving the Service, and increasing the security of the Service and network
          infrastructure;</p>
        <p>* You have consented to this collection and processing for a specific purpose;</p>
        <p>* It is necessary to fulfil our contractual obligations; or</p>
        <p>* It is necessary to comply with a legal obligation.</p>
        <p>Where we rely on our legitimate interests to process your personal data, you have the right to object. More
          information on exercising this right can be found in section 11 addressing Individual Rights. Some examples
          of
          our
          legitimate interests and the data being processed include:</p>
        <p>* Network and information security (IP address, user/device ID)</p>
        <p>* Customer Support and fraud prevention (email address)</p>
        <p>* Improving our products and services (activity logs)</p>
        <p>Where we rely on your consent to process your personal data, you have the right to withdraw or decline
          consent at
          any time. If you wish to withdraw your consent, please contact us using the information in section 12
          regarding
          the
          Contacts for Individual Rights Requests.</p>
        <p>If you have any questions about or need further information concerning the legal basis on which we collect
          and
          use
          your personal data, please contact us at Support@VoVoGamer.com.</p>
        <br><br><br>
        <b>Other</b><br>
        <p>We have security measures in place to protect the loss, misuse and alteration of the information under our
          control.
          These include a firewall to prevent unauthorized access to our systems and maintenance of registration
          information
          in a separate database. Although we will exercise reasonable care in providing secure transmission of
          information
          between your computer and our servers, we cannot ensure or warrant the security of any information
          transmitted
          to us
          over the Internet and we accept no liability for any unintentional disclosure.</p>
        <p>We may offer chat, user reviews, multi-player games, bulletin board, or other public functions and any
          posting by
          you is considered public information available to other users. Any posting is governed by our Terms of Use.
          You
          should take care to not use personal information in your screen name or other information that might be
          publicly
          available to other users.</p>
        <p>If a user elects to use our site for informing a friend about our site or particular features or content,
          we ask
          them for the friend's name and e-mail address. We will automatically send the friend a one-time e-mail
          inviting
          them
          to visit our site. We store this information for the sole purpose of sending this one-time e-mail.</p>
        <p>Your personal information is password-protected, and you should take appropriate measures to protect the
          secrecy
          of
          your password. If you use a computer in a public place or share a computer with others, remember to sign out
          and
          close your browser window when you finish a session with us in order to prevent others from accessing your
          personal
          information.</p>
        <p>We maintain archives of our web logs, database, and other systems and information. Please note that it is
          possible
          some of this information may remain archived after we delete the information from its active database (see
          above
          regarding web logs). We maintain archives for disaster recovery, legal and other non-marketing purposes.</p>
        <p>Learn more about <a href="http://www.google.com/intl/en/policies/privacy/partners/" target="_blank">How
            Google
            uses
            data.</a></p>
        <br><br><br>
        <b>Binding Agreement</b><br>
        <p>Your use of our site, including any dispute concerning privacy, is subject to this Privacy Policy and our
          Terms
          of Use. By using our Site, you are accepting the practices set out in this Privacy Policy and our Terms of
          Use.
          We
          reserve the right to modify this Privacy Policy at any time by posting the changes on this page. Please
          check
          the revision date at the bottom of this page to determine if the statement has been modified since you last
          reviewed
          it. Your continued use of any portion of our site following posting of the updated Privacy Policy will
          constitute
          your acceptance of the changes.</p>
        <br><br><br>
        <b>Contact information</b><br>
        <p>If you have any questions concerning this Privacy Policy, please contact us at Support@VoVoGamer.com.</p>
        <br><br><br>
        <p>11/23/2022</p>
         <Tips />
      </div>
    </div>
  </div>
</template>

<script>
import Tips from "@/components/Tips.vue";
export default {
  components: {  Tips },

}
</script>
<style lang="scss">
@import '~@/assets/scss/explanation';
</style>